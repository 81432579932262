.MuiPickersDay-dayDisabled {
  color: rgba(0, 0, 0, 0.26) !important;
}

.MuiPickersDay-daySelected {
  color: white !important;
}

.MuiPickersDay-daySelected p {
  font-weight: 700 !important;
}

.DocumentsTableGrid {
  height: 100%;
}

.DocumentsTableGrid > div:first-child {
  width: calc(100% - 0.5px);
}

.DocumentsTableCell *, .FinmonitoringTableCell * {
  font-size: 14px !important;
}

.OrdersTableCell *,
.OrdersTableCell {
  font-size: 12px !important;
  line-height: 20px !important;
}

.CustomPaging {
  margin-top: 40px;
}
.HolidayTableCell *, .HolidayTableCell {
  font-size: 12px !important;
}